<template>
    <IFrame class="h-100" :src="url" ref="viewerRef" />
</template>
<script setup lang="ts">
    import { computed, ref } from "vue";
    import IFrame from 'o365.vue.components.Iframe.vue';

    interface Props {
        fileName: string,
        primKey: string,
        viewName: string,
        id: number
    };

    const viewerRef = ref(null);

    const encodeObject = (pValue: any) => {
        const jsonString = JSON.stringify(pValue);
        const encodedString = btoa(encodeURIComponent(jsonString));
        return encodedString;
    }

    const props = defineProps<Props>();
    const url = computed(() => { return `/nt/foxit9-viewer?params=${encodeObject(props)}`})

    defineExpose({
        viewerRef
    })
</script>